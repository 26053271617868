<template>
  <div class="one-trust-cookie-page" />
</template>

<script>
export default {
  name: 'OneTrustCookiePage',
  mounted() {
    // add any cookie that has to be categorized by OneTrust cookie scanner
    document.cookie = 'dw_Technical_cookie=opt-in;';
    document.cookie = 'dw_Advertisement_cookie=opt-in;';
  },
};
</script>
